<template>
  <div class="order-brief">
      <div class="row mb-4">
        <div class="col-6 col-lg-3">
          <h5 class="font-weight-bold mb-0">Item</h5>
          <p>{{order.cart.product.name}}</p>
        </div>
        <div class="col-6 col-lg-3">
          <h5 class="font-weight-bold mb-0">Quantity</h5>
          <p>1</p>
        </div>
        <div class="col-6 col-lg-3">
          <h5 class="font-weight-bold mb-0">Duration</h5>
          <p>{{calculateDeliveryTime(order.delivery_time)}}</p>
        </div>
        <div class="col-6 col-lg-3">
          <h5 class="font-weight-bold mb-0">Amount</h5>
          <p>${{$filters.money_format(order.amount)}}</p>
        </div>
      </div>
      <feature-list :cart="order.cart" class="mb-4" />
      <addons :addons="addons" class="mb-4" />
      <requirements  class="mb-4" :requirements="requirements" />
      <h4 class="mb-0 text-primary">More Description</h4>
      <hr class="mt-2">
      <div v-html="order.description">
        
      </div>
      <p v-if="!order.description" class="mb-0 text-muted"><em>no description to show</em></p>
  </div>
</template>

<script>

import FeatureList from "./FeatureList.vue"
import Requirements from "./Requirements.vue"
import Addons from "./Addons.vue"

export default {
  components:{
    Addons,
    FeatureList,
    Requirements
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    addons(){
      return this.order.cart.addons
    },
    requirements(){
      return this.order.cart.requirements
    },
  },
}

</script>

