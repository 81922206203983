<template>
    <div class="row g-3 delivery-unit">
        <div class="col-12">
            <div class="">
                 <vue-dropzone id="deliveryDropZone" ref="deliveryDropZone"
                    :use-custom-slot="true" :maxFiles="10">
                  <div class="needsclick m-0">
                    <h5>Drop files here or click to upload.</h5>
                    <span class="text-muted small">
                      (Ai, video files should be zipped)
                    </span>
                  </div>
                </vue-dropzone>
            </div>
        </div>
        <div class="col-12">
            <div class="">
                <button @click.prevent="deliverFiles()" type="button"
                 class="btn btn-primary px-lg-5 mt-2"> Submit delivery </button>
            </div>
        </div>
    </div>
</template>
<script>
import VueDropzone from "./../VueDropzone.vue";

export default {
    components: {
        VueDropzone,
    },
    computed:{
        order(){
            return this.$store.state.orderList.order
        }
    },
    methods:{
        deliverFiles(){
            if(!this.collectFiles().length){ this.alertError("at least a file is Required"); return;}
            let formData = new FormData();
            this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/deliveries/${this.order.id}/create`, formData)
                .then(response => {
                    this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.initializeAll()
                            this.$store.commit('orderList/UPDATE_DELIVERY', response.data.data.delivery)
                        }
                })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.deliveryDropZone){
                arrafile = [...this.$refs.deliveryDropZone.getAcceptedFiles()];
            }
            return arrafile
        },
        initializeAll(){
            if(this.$refs.deliveryDropZone){this.$refs.deliveryDropZone.removeAllFiles();}
        }
    }
}
</script>
