<template>
  <div class="order-overview">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <button @click="$router.go(-1)" type="button" class="btn btn-dark"> Back</button> 
        </div>
      </div>
    </div>  
    <div v-if="isLoading" class="row">
      <div class="col-12">
        <is-loading />
      </div>
    </div>
    <div v-if="!isLoading" class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div v-if="order.status == 'draft'" >
              <b-alert :show="true"  variant="warning">
                <h5 class="alert-heading">Saved as draft!</h5>
                <p class="mb-0">
                  <span class="me-2">This order was saved as draft. Proceed to make payment so that this order can be processed.</span>
                </p>
                <hr class="my-2">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-outline-primary me-2" :href="absoluteUrl(`/projects/${order.id}/payment`)">Pay Now!</a>
                  <a class="btn btn-outline-danger" @click.prevent="deleteDraft()" href="#">Delete Draft!</a>
                </div>
              </b-alert>
            </div>
            <div v-else>
              <div class="mb-4">
                <h3 class="mb-2 font-weight-bold">{{product.name}}</h3>
                <p>Status: <strong class="text-uppercase me-2">{{order.status}}</strong> Amount: <strong class="text-uppercase">${{$filters.money_format(order.amount)}}</strong> </p>
                <p>Purchase was made by <strong>{{order.buyer.first_name}}.</strong> <span class="me-2">Project No: <strong>#{{order.number}}</strong></span> <a class="text-success" :href="absoluteUrl(`/${product.slug}`)">view product</a></p>
              </div>
              <div class="mb-4 d-flex">
                <div class="soft-bread w-auto overflow-hidden">
                  <span class="soft-bread-item border-right"
                    :class="{'bg-primary text-white': order.stage == 'design'}"><span style="z-index: 2;">DESIGN</span></span>
                    <span class="soft-bread-item border-right"
                    :class="{'bg-primary text-white': order.stage == 'finalist'}"><span style="z-index: 2;">FINALIST</span></span>
                  <span class="soft-bread-item"
                  :class="{'bg-primary text-white': order.stage == 'finished'}"><span style="z-index: 2;">FILES</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-4">
          <div class="col-12">
            <b-tabs active-tab-class="active" 
              nav-class="bg-white shadow nav-tabs-custom nav-primary mb-3">
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <i class="mdi mdi-book-outline me-1"></i>
                  <span class="nav-label">Brief</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-brief />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 2">
                <template v-slot:title>
                  <i class="mdi mdi-file-image me-1"></i>
                  <span class="nav-label">Delivery</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-delivery />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderBrief from "@/components/order/OrderBrief.vue"
import OrderDelivery from "@/components/order/OrderDelivery.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "order-overview",
  components: {
    OrderBrief,
    OrderDelivery,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    '$route.params.orderId': function (id){
      if(id) this.fetchOrder()
    },
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.activeUser
    },
    product(){
      return this.order.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
  },
  methods:{
    deleteDraft() {
       Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this order",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${this.order.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path:'/orders'})
            }
          })
        }
      });
    },
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        } 
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
.order-overview .soft-bread{
    background: rgba(var(--bs-primary-rgb), 0.09)
}

.order-overview .soft-bread .soft-bread-item{
    display:inline-block;
    color:var(--bs-primary);
    padding: 0.2rem 0.8rem;
}

</style>
